
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
























































































































$full: l;

.my-resa-outer {
  position: relative;
  overflow: hidden;
}

.my-resa {
  min-height: 50vh;
}

.my-resa__nav,
.my-resa__view {
  .has-nav > & {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.my-resa__nav {
  position: absolute;
  z-index: 10;
  overflow: auto;
  width: 100%;
  max-width: 30rem;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.25s $ease-out-cubic;

  .is-sidebar-open & {
    transform: translateX(0);
  }

  @include mq($header-breakpoint) {
    position: relative;
    overflow: visible;
    width: col(6, 24);
    max-width: none;
    height: auto;
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: col(2, 24) !important;
    transform: none;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.my-resa__view {
  width: 100%;

  .has-nav > & {
    @include mq($full) {
      padding-right: col(1, 24);
      padding-left: col(2, 24);
    }

    @include mq($header-breakpoint) {
      width: col(18, 24);
      padding-left: col(1, 24);
    }
  }

  @include mq($full) {
    &[class*='MyPpp'],
      // &[class*='MyEan'],
    &[class*='MyReset'],
    &[class*='MySignup'],
    &[class*='MyPassword'] {
      width: 100%;
      padding: 0 col(2, 24);
    }
  }
}

.my-resa__dev {
  width: 100%;
}

.loader {
  min-height: 70vh;
}
